/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon64IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon64Icon(props: Icon64IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 12 12"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g clipPath={"url(#zdweEmaPCQ-Na)"}>
        <path
          d={
            "M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z"
          }
          fill={"currentColor"}
        ></path>
      </g>

      <defs>
        <clipPath id={"zdweEmaPCQ-Na"}>
          <path fill={"currentColor"} d={"M0 0h12v12H0z"}></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon64Icon;
/* prettier-ignore-end */
